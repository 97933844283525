import React,{useState} from "react";
import Privacypolicy from "./Privacypolicy";
import Terms from "./Terms";
import { Modal,Button } from "react-bootstrap";
import axios from "axios";
import { baseURL } from "./Apis";
import { useNavigate } from 'react-router-dom';
import { MdCancel } from "react-icons/md";
import { encryptor } from "./Encryption";
import { RotatingLines } from 'react-loader-spinner'

const Setting = () => {
  const [sendMail, setSendMail]=useState(false)
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
    var username:any=sessionStorage.getItem('username')===undefined?'':sessionStorage.getItem('username');
    var user_id:any=sessionStorage.getItem('user_id')===undefined?'':sessionStorage.getItem('user_id');
    var user_role:any=sessionStorage.getItem('userRoleId')===undefined?'':sessionStorage.getItem('userRoleId');
    var subscribedFlag:any=sessionStorage.getItem('subscribedFlag')==undefined?'false':sessionStorage.getItem('subscribedFlag');
    const authTokenTest =   sessionStorage.getItem("apiToken") === undefined? 0: sessionStorage.getItem("apiToken");   
    const authToken =encryptor.decryptData(authTokenTest,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414');
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const [showTerms, setShowTerms] = useState(false);
  const handleShowTerms = () => setShowTerms(true);
  const handleCloseTerms = () => setShowTerms(false);

  const [show, setShow] = useState(false);
  const handleCloseD = () => setShow(false);
  const handleShowD = () => setShow(true);

  const [showS, setShowS] = useState(false);
  const handleCloseS = () => setShowS(false);
  const handleShowS = () => setShowS(true);

  const [showC, setShowC] = useState(false);
  const handleCloseC = () => setShowC(false);
  const handleShowC = () => setShowC(true);

  const handleCheckboxChange = (e:any) => {
    setSendMail(e.target.checked);
  };


  const deleteModal=()=>{
    setShow(true)
  }
  const DeleteAccount=async()=>{
    if(sendMail===true){
    const link1 = `${baseURL}/send_lease_data`;
    axios
      .post(link1, null, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
          
      })
    }
    const link = `${baseURL}/deleteUser/${user_id}`;
    axios
      .patch(link, null, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
          sessionStorage.clear();
          sessionStorage.setItem('authenticated', 'false');
          navigate('/login');
      })
      .catch((error) => {
        // Handle error here
      });
  }

  const CancelSubscription=async()=>{
    handleCloseS();
    setLoader(true)
    const link = baseURL+'cancel_subscription';
    await axios
      .post(link, null, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        setLoader(false)
          setShowC(true)
      })
      .catch((error) => {
        setLoader(false)
        alert(error)
        // Handle error here
      });
      
  }

  return (
    <>
    {loader == true ?
      <div id="loader-container">
        <div id="loader">
          <RotatingLines
            strokeColor="#0072B2"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      </div> : null}
      <div className="titlename">User Setting</div>
      <div style={{ display: "flex", justifyContent: "left", marginTop:'30px', fontFamily:'Poppins-Medium', fontSize:'16px' }}>
        <span>Account</span><br/>
      </div>
      <div className="col-8 row" style={{textAlign:'start'}}>
      <div className='col-2' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#5C5C5C'}}>User Name :</span>
      </div>
      <div className='col-4' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#25282B'}}>{username}</span>
      </div>
      </div>
      {user_role=='1'?null:<>
      <div className="col-8 row" style={{textAlign:'start'}}>
      <div className='col-2' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#5C5C5C'}}>Plan :</span>
      </div>
      <div className='col-4' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#25282B'}}>{subscribedFlag=='true'?'Premium':'Freemium'}</span>
      </div>
      </div>
      <div style={{ display: "flex", justifyContent: "left"}}>
      <button type="button" style={{ backgroundColor: '#FFDEDE',marginTop:'20px', color: "#FF5A66", fontFamily: "Poppins-Medium" ,fontSize:"14px", border:'solid #FF5A66 1px' }} 
                 className={`btn btn-block rounded-pill col-2`} onClick={(e)=>deleteModal()}>
                  Delete Account
      </button>
      </div> 
      {subscribedFlag=='true'?
      <div style={{ display: "flex", justifyContent: "left"}}>
      <button type="button" style={{ backgroundColor: '#FFDEDE',marginTop:'20px', color: "#FF5A66", fontFamily: "Poppins-Medium" ,fontSize:"14px", border:'solid #FF5A66 1px' }} 
                 className={`btn btn-block rounded-pill col-2`} onClick={()=>{setShowS(true)}}>
                  Cancel Subscription
      </button>
      </div> :null}</>}
                <hr />

                <div style={{ display: "flex", justifyContent: "left", marginTop:'30px', fontFamily:'Poppins-Medium', fontSize:'16px' }}>
        <span>About</span><br/>
      </div>
      <div className="col-8 row" style={{textAlign:'start'}}>
      <div className='col-2' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#5C5C5C'}}>Website Name :</span>
      </div>
      <div className='col-4' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#25282B'}}>Portfolio One Admin Portal</span>
      </div>
      </div>
      <div className="col-8 row" style={{textAlign:'start'}}>
      <div className='col-2' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#5C5C5C'}}>Privacy Policy :</span>
      </div>
      <div className='col-4' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#0072B2', cursor:'pointer'}} onClick={handleShow}>Portfolio One</span>
      </div>
      </div>
      <div className="col-8 row" style={{textAlign:'start'}}>
      <div className='col-2' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#5C5C5C'}}>Terms of Service :</span>
      </div>
      <div className='col-4' style={{marginTop:'10px',fontFamily:'Poppins-Medium', fontSize:'14px' }}>
        <span style={{color:'#0072B2', cursor:'pointer'}} onClick={handleShowTerms}>Portfolio One</span>
      </div>
      </div>
      <div
                  className=""
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontFamily: "Poppins-Medium",
                    color: "#636363",
                    marginTop:'10px'
                  }}
                >
                  <i className="fa fa-copyright" aria-hidden="true"></i> Digital Finance Transformation and contributors 2023
                </div>
      <Privacypolicy closeModal={handleClose} openModal={showModal} />
      <Terms closeModal={handleCloseTerms} openModal={showTerms} />
      <Modal show={show} onHide={handleCloseD} centered style={{fontFamily:'Poppins-Medium',fontSize:'14px'}}>
        <Modal.Body>
        <p>Are you sure you want to delete your account? This action cannot be undone.</p>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input type="checkbox" id="sendEmailCheckbox"  checked={sendMail}
              onChange={(e:any)=>handleCheckboxChange(e)} />
      <label htmlFor="sendEmailCheckbox" style={{marginLeft:'10px', fontStyle:'italic'}}>Email me the backup of my data</label>
    </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseD}>
            Cancel
          </Button>
          <Button variant="danger" onClick={DeleteAccount}>
            Delete Account
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showS} onHide={handleCloseS} centered style={{fontFamily:'Poppins-Medium',fontSize:'14px'}}>
        <Modal.Body>
        Are you sure you want to cancel your subscription? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseS}>
            Close
          </Button>
          <Button variant="danger" onClick={CancelSubscription}>
            Cancel Subscription
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showC} onHide={handleCloseC} backdrop="static" style={{ fontFamily: 'Poppins-Medium', fontSize: '14px' }}>
        <Modal.Body style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontFamily: 'Poppins-Medium', fontSize: '18px' }}>
            <i className="fa fa-check-circle fa-lg mx-2" style={{ color: '#00A978' }}></i>Your subscription has been cancelled.
          </span>
          {/* Close button */}
          <MdCancel className="" style={{cursor:'pointer',fontSize:'24px'}} aria-hidden="true" onClick={()=>setShowC(false)} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Setting;
