import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../CSS/common.css'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Snackbar from '@mui/material/Snackbar';
import DialogTitle from '@mui/material/DialogTitle';
import * as yup from 'yup';
import { RotatingLines } from 'react-loader-spinner'
import moment from 'moment';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { baseURL } from './Apis';
import { encryptor } from './Encryption';
import CreatableSelect from "react-select/creatable";
import Select from "react-select";


const isLatitude = (value: any) => {
  return !value || (parseFloat(value) >= -90 && parseFloat(value) <= 90);
}
const isLongitude = (value: any) => {
  return !value || (parseFloat(value) >= -180 && parseFloat(value) <= 180);
}



const validationSchemaSection = yup.object().shape({
  building: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  state_district_province: yup.string().required(),
  postal_code: yup.number().required(),
  region: yup.string().required(),
  latitude: yup.number().test(isLatitude).required(),
  longitude: yup.number().test(isLongitude).required(),
  lease_commencement_date: yup.date().required(),
  lease_expiration_date: yup.date().required(),
  annualized_base_rent: yup.number().required(),
  additional_facilities_cost: yup.number().required(),
  facilities_cost_fully_loaded: yup.number().required(),
  strategy_top_opportunities: yup.string().required(),
  strategy_head_count_adjustment_in_year_of_change: yup.number().required(),
  strategy_seat_count_adjustment_in_year_of_change: yup.number().required(),
  strategy_square_foot_adjustment_in_year_of_change: yup.number().required(),
  strategy_occupancy_adjustment_in_year_of_change: yup.number().required(),
  strategy_year_of_change_yyyy: yup.number().required(),
  capacity_seats: yup.number().required(),
  surplus_deficit_seat_capacity: yup.number().required(),
  parking_capacity: yup.number().required(),
  cost_employee: yup.number().required(),
  cost_occupant: yup.number().required(),
  cost_seat: yup.number().required(),
  sf_employee: yup.number().required(),
  sf_occupant: yup.number().required(),
  sf_seat: yup.number().required(),
  rentable_sf: yup.number().required(),
  cost_sf: yup.number().required(),
  cost_sf_1: yup.number().required(),
  critical_decision_date: yup.date().required(),
  revenues: yup.number().required(),
  rmo_months: yup.number().required(),
  total_employee_hc: yup.number().required(),
  total_occupancy_hc: yup.number().required()
});

interface OptionType {
  value: string;
  label: string;
}

const Lease = () => {

  const authTokenTest =   sessionStorage.getItem("apiToken") === undefined? 0: sessionStorage.getItem("apiToken");   
  const authToken =encryptor.decryptData(authTokenTest,'9C102BD94E963BCD79066175A1FA6D82EBA701547861CAD26CF15991CBF12414');
  const [idCount, setIdCount] = useState(0)
  const [names, setNames] = useState<string[]>([]);
  const [countryData, setCountryData] = useState([]);
  const [digContent, setDigContent] = useState<React.ReactNode | null>(null);
  const [loader, setLoader] = useState(false)
  const [update, setUpdate] = useState(false)
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [accordion1,setAccordion1]=useState(true);
  const [accordion2,setAccordion2]=useState(true);
  const [accordion3,setAccordion3]=useState(true);
  const [accordion4,setAccordion4]=useState(true);
  const [businessUnits, setBusinessUnits]:any = useState([]);
   const [searchedText, setSearchedText] = useState("");
  const redirectToSiteList = () => {
    window.location.href = '../siteList';
  };
  var userEmail = sessionStorage.getItem('useremail') === undefined ? '' : sessionStorage.getItem('useremail')
  useEffect(() => {
    setLoader(true)
    
    var link = baseURL + 'getAllLeaseDataa';
    axios.get(link, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        setIdCount(response.data.count + 1) // Access the response data here
        if (sessionStorage.getItem('IdForUpdate')){
          //console.log(sessionStorage.getItem('IdForUpdate'))
          setUpdate(true);
          response.data.data.map((data:any,key:any)=>{
            if(data.lease_id === sessionStorage.getItem('IdForUpdate'))
            {
              delete data.created_at;
              delete data.updated_at;
              setInitialLeaseData(data)
              sessionStorage.removeItem('IdForUpdate')
            }
          })
      }
      var countryDataLink = "https://www.portfolioone.io/api/countries";
      axios.get(countryDataLink)
        .then((response) => {
          setCountryData(response.data)
          response.data.forEach((item: any) => {
            setNames(prevNames => [...prevNames, item.name]);
          });
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
          console.error('An error occurred:', error);
        });
      
        var businessUnitData = baseURL + 'business_units';
        axios.get(businessUnitData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          setBusinessUnits(response.data.data)
          //console.log(response.data.data)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
          console.error('An error occurred while fetching business units:', error);
        });


      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
    
  }, []);

  const leaseData = {
    additional_facilities_cost: "",
    address: "",
    annualized_base_rent: "",
    building: "",
    business_unit: "Commercial",
    capacity_seats: "",
    city: "",
    client_position: "TENANT",
    cost_employee: "",
    cost_occupant: "",
    cost_seat: "",
    cost_sf: "",
    cost_sf_1: "",
    country_code: "AC",
    country_name: "Ascension Island",
    critical_decision_date: "",
    document_type: "New Expense Lease",
    facilities_cost_fully_loaded: "",
    floor: "",
    latitude: "",
    lease_commencement_date: "",
    lease_expiration_date: "",
    lease_expiration_year: "",
    lease_id: "",
    lease_type: "None",
    leased_owned: "Leased",
    legacy_company: " ",
    longitude: "",
    opex_sga: "",
    opex_total_revenues: "",
    parking_capacity: "",
    postal_code: "",
    region: "",
    rentable_sf: "",
    revenues: "",
    rmo_months: "",
    sf_employee: "",
    sf_occupant: "",
    sf_seat: "",
    sga: "",
    site_id: "",
    space_use: "Office",
    state_district_province: "",
    status: "Active",
    strategy_head_count_adjustment_in_year_of_change: "",
    strategy_notes: "",
    strategy_occupancy_adjustment_in_year_of_change: "",
    strategy_optional_committed_or_na: "Optional",
    strategy_quarter_of_change: "Q1",
    strategy_real_estate_initiative: "Change During Life of Lease",
    strategy_reduction_addition_or_as_is: "Reduction",
    strategy_seat_count_adjustment_in_year_of_change: "",
    strategy_square_foot_adjustment_in_year_of_change: "",
    strategy_top_opportunities: "",
    strategy_type: "Primary",
    strategy_year_of_change_yyyy: "",
    sub_region: "APAC",
    surplus_deficit_seat_capacity: "",
    total_employee_hc: "",
    total_occupancy_hc: "",
    user_id: sessionStorage.getItem('useremail') === undefined ? '' : sessionStorage.getItem('useremail'),
    vacant: "Yes",
    verified_yn: "Yes"
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      border: state.isFocused ? "2px solid #0072B2" : "2px solid #0072B2",
      borderRadius: "10px",
      backgroundColor: state.isFocused ? "#F4FAFF" : "#F4FAFF",
      padding: "0px",
      width: "100%",
      fontFamily: "Poppins-Regular",
      outline: "none",
      boxShadow: "none",
      "&:hover": {
        border:"2px solid #0072B2",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      textAlign: "left", // Left-align the options
    }),
    option: (provided: any) => ({
      ...provided,
      textAlign: "left", // Left-align the individual options
    }),
    placeholder: (provided: any) => ({
      ...provided,
      textAlign: "left",
      color: "rgb(85,90,93)", // Left-align the placeholder
    }),
    singleValue: (provided: any) => ({
      ...provided,
      textAlign: "left", // Left-align the placeholder
    }),
  };

  const copyObjectWithoutOrgId=(original:any)=> {
    const newObj = Object.assign({}, original);
    delete newObj.org_id;
    delete newObj.org_name;
    delete newObj.user_name;
    return newObj;
  }
  const [activeTab, setActiveTab] = useState(1);
  const [InitialLeaseData, setInitialLeaseData] = useState({
    additional_facilities_cost: "",
    address: "",
    annualized_base_rent: "",
    building: "",
    business_unit: 'Commercial',
    capacity_seats: "",
    city: "",
    client_position: "TENANT",
    cost_employee: "",
    cost_occupant: "",
    cost_seat: "",
    cost_sf: "",
    cost_sf_1: "",
    country_code: "AC",
    country_name: "Ascension Island",
    critical_decision_date: "",
    document_type: "New Expense Lease",
    facilities_cost_fully_loaded: "",
    floor: "",
    latitude: "",
    lease_commencement_date: "",
    lease_expiration_date: "",
    lease_expiration_year: "",
    lease_id: "CAN10001",
    lease_type: "None",
    leased_owned: "Leased",
    legacy_company: " ",
    longitude: "",
    opex_sga: "",
    opex_total_revenues: "",
    parking_capacity: "",
    postal_code: "",
    region: "",
    rentable_sf: "",
    revenues: "",
    rmo_months: "",
    sf_employee: "",
    sf_occupant: "",
    sf_seat: "",
    sga: "",
    site_id: "",
    space_use: "Office",
    state_district_province: "",
    status: "Active",
    strategy_head_count_adjustment_in_year_of_change: "",
    strategy_notes: "",
    strategy_occupancy_adjustment_in_year_of_change: "",
    strategy_optional_committed_or_na: "Optional",
    strategy_quarter_of_change: "Q1",
    strategy_real_estate_initiative: "Change During Life of Lease",
    strategy_reduction_addition_or_as_is: "Reduction",
    strategy_seat_count_adjustment_in_year_of_change: "",
    strategy_square_foot_adjustment_in_year_of_change: "",
    strategy_top_opportunities: "",
    strategy_type: "Primary",
    strategy_year_of_change_yyyy: "",
    sub_region: "APAC",
    surplus_deficit_seat_capacity: "",
    total_employee_hc: "",
    total_occupancy_hc: "",
    user_id: sessionStorage.getItem('useremail') === undefined ? '' : sessionStorage.getItem('useremail'),
    vacant: "Yes",
    verified_yn: "Yes"
  })

  const AddLeaseData = async () => {
    setLoader(true);
    var checksectio4 = {
      building: InitialLeaseData.building,
        address: InitialLeaseData.address,
        city: InitialLeaseData.city,
        state_district_province: InitialLeaseData.state_district_province,
        postal_code: InitialLeaseData.postal_code,
        region: InitialLeaseData.region,
        latitude: InitialLeaseData.latitude,
        longitude: InitialLeaseData.longitude,
        lease_commencement_date: InitialLeaseData.lease_commencement_date,
        lease_expiration_date: InitialLeaseData.lease_expiration_date,
        annualized_base_rent: InitialLeaseData.annualized_base_rent,
        additional_facilities_cost: InitialLeaseData.additional_facilities_cost,
        facilities_cost_fully_loaded: InitialLeaseData.facilities_cost_fully_loaded,
        strategy_top_opportunities: InitialLeaseData.strategy_top_opportunities,
        strategy_head_count_adjustment_in_year_of_change: InitialLeaseData.strategy_head_count_adjustment_in_year_of_change,
        strategy_seat_count_adjustment_in_year_of_change: InitialLeaseData.strategy_seat_count_adjustment_in_year_of_change,
        strategy_square_foot_adjustment_in_year_of_change: InitialLeaseData.strategy_square_foot_adjustment_in_year_of_change,
        strategy_occupancy_adjustment_in_year_of_change: InitialLeaseData.strategy_occupancy_adjustment_in_year_of_change,
        strategy_year_of_change_yyyy: InitialLeaseData.strategy_year_of_change_yyyy,
      capacity_seats: InitialLeaseData.capacity_seats,
      surplus_deficit_seat_capacity: InitialLeaseData.surplus_deficit_seat_capacity,
      parking_capacity: InitialLeaseData.parking_capacity,
      cost_employee: InitialLeaseData.cost_employee,
      cost_occupant: InitialLeaseData.cost_occupant,
      cost_seat:InitialLeaseData.cost_seat,
      sf_employee: InitialLeaseData.sf_employee,
      sf_occupant: InitialLeaseData.sf_occupant,
      sf_seat: InitialLeaseData.sf_seat,
      rentable_sf: InitialLeaseData.rentable_sf,
      cost_sf: InitialLeaseData.cost_sf,
      cost_sf_1: InitialLeaseData.cost_sf_1,
      critical_decision_date: InitialLeaseData.critical_decision_date,
      revenues: InitialLeaseData.revenues,
      rmo_months: InitialLeaseData.rmo_months,
      total_employee_hc: InitialLeaseData.total_employee_hc,
      total_occupancy_hc: InitialLeaseData.total_occupancy_hc
    }
    try {
      await validationSchemaSection.validate(checksectio4, { abortEarly: false });
      InitialLeaseData.lease_id = InitialLeaseData.country_code + '' + idCount
      const checkDuplicateLink = `${baseURL}/check_lease_duplicate`;
      const duplicateResponse = await axios.post(
        checkDuplicateLink,
        { 
          building: InitialLeaseData.building.toString(),
          address: InitialLeaseData.address.toString(),
          floor: InitialLeaseData.floor.toString(),
          city: InitialLeaseData.city.toString(),
          postal_code: InitialLeaseData.postal_code.toString(),
          latitude: InitialLeaseData.latitude.toString(),
          longitude: InitialLeaseData.longitude.toString()
        }, // Adjust payload as needed
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      var link = baseURL+ 'addLeaseData';

      if (duplicateResponse.data.exists===true) {
        setLoader(false)
        setDigContent(
          <span>
            Data already exist in the database.
          </span>
        );
        setModalShow(true)
      }
      else{
      try {
        
        const checkUsername = await axios.post(link, InitialLeaseData, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (checkUsername.data.status === 200) {
          setLoader(false)
          setDigContent(<span ><i className="fa fa-check-circle fa-lg mx-2" style={{ color: "#00A978" }}></i>Site added successfully.</span>)
          setModalShow(true)
          setIdCount(idCount + 1)
          setInitialLeaseData(leaseData)

        }
      }
      catch (error) {
        // Handle errors here
        setLoader(false)
        setDigContent(<span >Error occured. Please try again<i className="fa fa-exclamation mx-2"></i></span>)
        setModalShow(true)
        // You can set an error state or show an error message to the user
      }
    }

    }
    catch (error: any) {
      setLoader(false)
      setDigContent(<span >Please fill all mandatory fields with valid data<i className="fa fa-exclamation mx-2"></i></span>)
      setModalShow(true)
      const errorMessages = error.errors.reduce((acc: any, err: any) => {
        const path = err.split(' ')[0]; // Extract the field name from the error message
        acc[path] = err;
        return acc;
      }, {});
      for (const key in errorMessages) {
        //console.log(key, errorMessages[key]);
        const errorMessage = errorMessages[key];
        const myElement: HTMLElement | null = document.getElementById(key);
        const errorContainer = document.getElementById(key + "Error");
        if (myElement) {
          myElement.classList.add("errorBorder");
        }
        if (errorContainer) {
          errorContainer.style.display = ""
        }
      }
    }
  }

  const UpdateLeaseData = async () => {
    setLoader(true)
    var checksectio4 = {
      building: InitialLeaseData.building,
        address: InitialLeaseData.address,
        city: InitialLeaseData.city,
        state_district_province: InitialLeaseData.state_district_province,
        postal_code: InitialLeaseData.postal_code,
        region: InitialLeaseData.region,
        latitude: InitialLeaseData.latitude,
        longitude: InitialLeaseData.longitude,
        lease_commencement_date: InitialLeaseData.lease_commencement_date,
        lease_expiration_date: InitialLeaseData.lease_expiration_date,
        annualized_base_rent: InitialLeaseData.annualized_base_rent,
        additional_facilities_cost: InitialLeaseData.additional_facilities_cost,
        facilities_cost_fully_loaded: InitialLeaseData.facilities_cost_fully_loaded,
        strategy_top_opportunities: InitialLeaseData.strategy_top_opportunities,
        strategy_head_count_adjustment_in_year_of_change: InitialLeaseData.strategy_head_count_adjustment_in_year_of_change,
        strategy_seat_count_adjustment_in_year_of_change: InitialLeaseData.strategy_seat_count_adjustment_in_year_of_change,
        strategy_square_foot_adjustment_in_year_of_change: InitialLeaseData.strategy_square_foot_adjustment_in_year_of_change,
        strategy_occupancy_adjustment_in_year_of_change: InitialLeaseData.strategy_occupancy_adjustment_in_year_of_change,
        strategy_year_of_change_yyyy: InitialLeaseData.strategy_year_of_change_yyyy,
      capacity_seats: InitialLeaseData.capacity_seats,
      surplus_deficit_seat_capacity: InitialLeaseData.surplus_deficit_seat_capacity,
      parking_capacity: InitialLeaseData.parking_capacity,
      cost_employee: InitialLeaseData.cost_employee,
      cost_occupant: InitialLeaseData.cost_occupant,
      cost_seat:InitialLeaseData.cost_seat,
      sf_employee: InitialLeaseData.sf_employee,
      sf_occupant: InitialLeaseData.sf_occupant,
      sf_seat: InitialLeaseData.sf_seat,
      rentable_sf: InitialLeaseData.rentable_sf,
      cost_sf: InitialLeaseData.cost_sf,
      cost_sf_1: InitialLeaseData.cost_sf_1,
      critical_decision_date: InitialLeaseData.critical_decision_date,
      revenues: InitialLeaseData.revenues,
      rmo_months: InitialLeaseData.rmo_months,
      total_employee_hc: InitialLeaseData.total_employee_hc,
      total_occupancy_hc: InitialLeaseData.total_occupancy_hc
    }
    const copiedObject = copyObjectWithoutOrgId(InitialLeaseData);
    try {
      await validationSchemaSection.validate(checksectio4, { abortEarly: false });
      var link = baseURL + 'updateLeaseData/' + copiedObject.lease_id;
      try {
        
        const checkUsername = await axios.put(link, copiedObject, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (checkUsername.data.status === 200) {
          setLoader(false)
          setDigContent(<span ><i className="fa fa-check-circle fa-lg mx-2" style={{ color: "#00A978" }}></i>Site Updated Successfully </span>)
          setModalShow(true)
          setTimeout(() => {
          navigate('../siteList')},2000)
        }
      }
      catch (error) {
        // Handle errors here
        setLoader(false)
        setDigContent(<span >Error occured. Please try again<i className="fa fa-exclamation mx-2"></i></span>)
        setModalShow(true)
        console.error('An error occurred:', error);
        // You can set an error state or show an error message to the user
      }
    }
    catch (error: any) {
      setLoader(false)
      setDigContent(<span >Please fill all mandatory fields with valid data<i className="fa fa-exclamation mx-2"></i></span>)
      setModalShow(true)
      const errorMessages = error.errors.reduce((acc: any, err: any) => {
        const path = err.split(' ')[0]; // Extract the field name from the error message
        acc[path] = err;
        return acc;
      }, {});
      for (const key in errorMessages) {
        //console.log(key, errorMessages[key]);
        const errorMessage = errorMessages[key];
        const myElement: HTMLElement | null = document.getElementById(key);
        const errorContainer = document.getElementById(key + "Error");
        if (myElement) {
          myElement.classList.add("errorBorder");
        }
        if (errorContainer) {
          errorContainer.style.display = ""
        }
      }
    }
  }

  const handleInputChange = (e: any) => {
    const { id, value } = e.target;
    const updatedLeaseData = {
      ...InitialLeaseData,
      [id]: value.trim(),
    }
    setInitialLeaseData(updatedLeaseData);
  }


  const ChangeCountryCode = (name: any) => {
    var value: any;
    countryData.map((val: any, key: any) => {
      if (val.name === name) {
        value = val.country
      }
    })
    const updatedLeaseData = { ...InitialLeaseData };
    updatedLeaseData.country_name = name;
    updatedLeaseData.country_code = value;
    setInitialLeaseData(updatedLeaseData);
  }


  const setLeaseExpirationYear = (expdate: any) => {
    var expyear = moment(expdate).format('YYYY')
    const updatedLeaseData = { ...InitialLeaseData };
    updatedLeaseData.lease_expiration_date = expdate
    updatedLeaseData.lease_expiration_year = expyear
    setInitialLeaseData(updatedLeaseData);
  }


  const removeInputError = (key: any) => {
    const myElement: HTMLElement | null = document.getElementById(key);
    const errorContainer = document.getElementById(key + "Error");
    if (myElement) {
      myElement.classList.remove("errorBorder");
    }
    if (errorContainer) {
      errorContainer.style.display = "none"
    }
  }

  const handlePageRefresh = () => {
    // Refresh the page
    //window.location.reload();
    window.location.href = '../siteList';
  };

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);

  // Transform data to match react-select's required format
  const options = businessUnits.map((unit:any) => ({
    value: unit.name,
    label: unit.name,
  }));

  const handleInputChangeBU = (inputValue: string, { action }: { action: string }) => {
    if (action === 'input-change') {
      setSearchedText(inputValue.trim());
    }
  };

  const handleSelectChangeBU = (selectedOption: any) => {
    const updatedLeaseData = {
      ...InitialLeaseData,
      business_unit: selectedOption ? selectedOption.value : "Commercial", // Handle clearable case
    };
    setInitialLeaseData(updatedLeaseData);
  };


  const addNewBU = () => {
    setLoader(true)
    //console.log(searchedText.trim().toUpperCase());
    const seaText: any = searchedText.trim().toUpperCase();
    var orgdata = {
      name: searchedText.trim().toUpperCase(),
    };
    var link = baseURL + "business_units";
    axios
      .post(link, orgdata,{
        headers: {
          Authorization: `Bearer ${authToken}`,
        },

      })
      .then((response) => {

        if(response.data.msg=='Business Unit Added Successfully'){
          const updatedLeaseData = {
            ...InitialLeaseData,
            business_unit: response.data.name ? response.data.name : "Commercial", // Handle clearable case
          };
          setInitialLeaseData(updatedLeaseData);
        var businessUnitData = baseURL + 'business_units';
        axios.get(businessUnitData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          setBusinessUnits(response.data.data)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
          console.error('An error occurred while fetching business units:', error);
        });

      }

      })
      .catch((error) => {
        //console.error("An error occurred:", error);
      });
  };
  return (<>
    {loader == true ?
      <div id="loader-container">
        <div id="loader">
          <RotatingLines
            strokeColor="#0072B2"
            strokeWidth="5"
            animationDuration="0.75"
            width="100"
            visible={true}
          />
        </div>
      </div> : null}
 
 
    <div className='' style={{margin:"40px"}}>
      <div className="row mt-5 mb-3" style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: "1", textAlign: "left" }}>
                <i className="fa fa-arrow-circle-o-left fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"20px" }} onClick={(e) => redirectToSiteList()}></i>
                <label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"20px",color: "#0072B2"}} onClick={(e) => redirectToSiteList()}>&nbsp;Site List</label>
              </div>
              <div style={{ flex: "1", textAlign: "right" }}>
              <button type="button" style={{ backgroundColor: '#CCE3F0', color: "#0072B2", fontFamily: "Poppins-Medium" ,fontSize:"16px", border:"1px solid #0072B2"}} className="btn btn-block rounded-pill mx-2" onClick={handlePageRefresh}>
                Cancel
              </button>
                {update? 
                <button type="button" style={{ backgroundColor: '#0072B2', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px"}} className="btn btn-block rounded-pill mx-2" onClick={UpdateLeaseData}>
                Update Site
              </button>
                :
                <button type="button" style={{ backgroundColor: '#0072B2', color: "white", fontFamily: "Poppins-Medium" ,fontSize:"16px" }} className="btn btn-block rounded-pill mx-2" onClick={AddLeaseData}>
                  Add Site
                </button>}

              </div>
            </div>
            <div className="container mt-4 mb-3">
    {/*   <div className="card">
        <div className="card-body">

          <div className="row">
            <div className="col-md-4">
              <div className="form-group" style={{ textAlign: "start" }}>
                <label  >Lease ID</label>
                <h6 className="" id="leaseid">{!update?InitialLeaseData.country_code + "" + idCount:InitialLeaseData.lease_id} </h6>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group" style={{ textAlign: "center" }}>
                <label  >Site ID</label>
                <h6 className="" id="leaseid" >-</h6>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group" style={{ textAlign: "end" }}>
                <label  >Document Type</label>
                <h6 className="" id="document_type" >{InitialLeaseData.document_type}</h6>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
      <Accordion defaultExpanded={true} onChange={()=>setAccordion1(!accordion1)}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography style={{color:"#0072B2", fontSize:"18px", fontFamily:"Poppins-SemiBold"}}>
            {accordion1?<i className="fa fa-minus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>:<i className="fa fa-plus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>}
            Address Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Building<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="building" value={InitialLeaseData.building} onChange={(e: any) => { handleInputChange(e); removeInputError('building') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="buildingError">Please enter valid building details.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Address<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="address" value={InitialLeaseData.address} onChange={(e: any) => { handleInputChange(e); removeInputError('address') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="addressError">Please enter valid address.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Floor</label>
                  <input type="text" className="form-control inputStyle" id="floor" value={InitialLeaseData.floor} onChange={handleInputChange} />
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >City<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="city" value={InitialLeaseData.city} onChange={(e: any) => { handleInputChange(e); removeInputError('city') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="cityError">Please enter valid city.</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >State / District / Province<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="state_district_province" value={InitialLeaseData.state_district_province} onChange={(e: any) => { handleInputChange(e); removeInputError('state_district_province') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="state_district_provinceError">Please enter valid state.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Postal Code<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="postal_code" value={InitialLeaseData.postal_code} onChange={(e: any) => { handleInputChange(e); removeInputError('postal_code') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="postal_codeError">Please enter valid postal code.</label>
                </div>
              </div>
            </div>

            <div className="row mt-3">


              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Country<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="country_name"
                    value={InitialLeaseData.country_name}
                    onChange={(e: any) => { handleInputChange(e); ChangeCountryCode(e.target.value) }}
                  >
                    {
                      names.map((name: any, index: any) => {
                        return (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Country Code<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="country_code" value={InitialLeaseData.country_code} onChange={handleInputChange} disabled />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Region<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="region" value={InitialLeaseData.region} onChange={(e: any) => { handleInputChange(e); removeInputError('region') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="regionError">Please enter valid region.</label>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: 'start' }}>
                  <label>Sub - Region<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="sub_region"
                    name="sub_region"
                    value={InitialLeaseData.sub_region}
                    onChange={handleInputChange}
                  >
                    <option value="APAC">APAC</option>
                    <option value="LATAM">LATAM</option>
                    <option value="EMEA">EMEA</option>
                    <option value="N. AMER">N. AMER</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Latitude<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="latitude" value={InitialLeaseData.latitude} onChange={(e: any) => { handleInputChange(e); removeInputError('latitude') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="latitudeError">Please enter valid latitude.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Longitude<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="longitude" value={InitialLeaseData.longitude} onChange={(e: any) => { handleInputChange(e); removeInputError('longitude') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="longitudeError">Please enter valid longitude.</label>
                </div>
              </div>
            </div>

           {/*   <div className="row " style={{marginTop:"100px"}} >
              <div className="col-md-12" >
                <div style={{ textAlign: "end" }}>
                  <label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"25px",color: "#0072B2"}} onClick={(e) => changeActiveTab(2,1)}>Next&nbsp;</label><i className="fa fa-angle-double-right fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"25px" }} onClick={(e) => changeActiveTab(2,1)}></i>
                </div>
              </div>
            </div>  */}
          </> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true} onChange={()=>setAccordion2(!accordion2)}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography style={{color:"#0072B2", fontSize:"18px", fontFamily:"Poppins-SemiBold"}}>
          {accordion2?<i className="fa fa-minus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>:<i className="fa fa-plus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>}
            Lease/Property Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Status<span style={{ color: "#FE8485" }}>*</span></label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label>
                      <input
                        className=''
                        type="radio"
                        name="status"
                        id="status"
                        value="Active"
                        checked={InitialLeaseData.status === "Active"}
                        onChange={handleInputChange}
                      />
                      <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>Active</span>
                    </label>

                    <label style={{ marginLeft: "10px" }}>
                      <input
                        type="radio"
                        name="status"
                        id="status"
                        value="Offline"
                        checked={InitialLeaseData.status === "Offline"}
                        onChange={handleInputChange}
                      />
                      <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>Offline</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Lease Type<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="lease_type"
                    value={InitialLeaseData.lease_type}
                    onChange={handleInputChange}
                  >
                    <option value="None">None</option>
                    <option value="NET">NET</option>
                    <option value="Gross">Gross</option>
                    <option value="Modified Gross">Modified Gross</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Leased / Owned<span style={{ color: "#FE8485" }}>*</span></label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label>
                      <input
                        type="radio"
                        name="leased_owned"
                        id="leased_owned"
                        value="Leased"
                        checked={InitialLeaseData.leased_owned === "Leased"}
                        onChange={handleInputChange}
                      />
                      <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>Leased</span>
                    </label>

                    <label style={{ marginLeft: "10px" }}>
                      <input
                        type="radio"
                        name="leased_owned"
                        id="leased_owned"
                        value="Owned"
                        checked={InitialLeaseData.leased_owned === "Owned"}
                        onChange={handleInputChange}
                      />
                      <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>Owned </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Vacant<span style={{ color: "#FE8485" }}>*</span></label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label>
                      <input
                        type="radio"
                        name="vacant"
                        id="vacant"
                        value="Yes"
                        checked={InitialLeaseData.vacant === "Yes"}
                        onChange={handleInputChange}
                      />
                      <span style={{ marginLeft: "5px" }}>Yes</span>
                    </label>

                    <label style={{ marginLeft: "15px" }}>
                      <input
                        type="radio"
                        name="vacant"
                        id="vacant"
                        value="No"
                        checked={InitialLeaseData.vacant === "No"}
                        onChange={handleInputChange}
                      />
                      <span style={{ marginLeft: "5px" }}>No</span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Verified<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="verified_yn"
                    value={InitialLeaseData.verified_yn}
                    onChange={handleInputChange}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="UNV">UNV</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Space Use<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="space_use"
                    value={InitialLeaseData.space_use}
                    onChange={handleInputChange}
                  >
                    <option value="Administration">Administration</option>
                    <option value="Airport Hangar">Airport Hangar</option>
                    <option value="Amenity">Amenity</option>
                    <option value="Call Center">Call Center</option>
                    <option value="Coworking">Coworking</option>
                    <option value="Datacenter">Datacenter</option>
                    <option value="Distribution">Distribution</option>
                    <option value="Land">Land</option>
                    <option value="Lab/Office">Lab/Office</option>
                    <option value="Learning Center">Learning Center</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Office">Office</option>
                    <option value="Parking">Parking</option>
                    <option value="R&D">R&D</option>
                    <option value="R&D/Manufacturing">R&D/Manufacturing</option>
                    <option value="Sales Office">Sales Office</option>
                    <option value="Storage">Storage</option>
                    <option value="Warehouse">Warehouse</option>
                    
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Business Unit<span style={{ color: "#FE8485" }}>*</span></label>
                  
                  {/* <select
                    className="form-control inputStyle"
                    id="business_unit"
                    value={InitialLeaseData.business_unit}
                    onChange={handleInputChange}
                  >
                    {
                      businessUnits.map((val:any,index:any)=>{
                        return(
                        <option value={val.name}>{val.name}</option>)
                      })
                    }
                  </select>  */}

     

                  <Select
                    className=""
                    id="business_unit"
                    placeholder="Search or add a Business Unit"
                    value={
                      InitialLeaseData.business_unit
                        ? options.find((option:any) => option.value === InitialLeaseData.business_unit)
                        : null
                    }
                    options={options}
                    onChange={handleSelectChangeBU}
                    onInputChange={handleInputChangeBU}
                    //onCreateOption={handleCreate}
                    styles={customStyles}
                    //isClearable
                    noOptionsMessage={() => (
                      searchedText.length>-1?
                      <div
                        className="btn btn-block col-10 rounded-pill"
                        style={{
                          backgroundColor: "#36BF76",
                          color: "#FFF",
                        }}
                        onClick={() => addNewBU()}
                      >
                         Add Business Unit
                      </div>:null
                    )}
                  />
                </div>
              </div>


              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Client Position<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="client_position"
                    value={InitialLeaseData.client_position}
                    onChange={handleInputChange}
                  >
                    <option value="TENANT">TENANT</option>
                    <option value="SUBTENANT">SUBTENANT</option>
                    <option value="OWNER">OWNER</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label > Legacy Company</label>
                  <input type="text" className="form-control inputStyle" id="legacy_company" value={InitialLeaseData.legacy_company} onChange={handleInputChange} />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Lease Commensement Date<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="date" className="form-control inputStyle" id="lease_commencement_date" value={InitialLeaseData.lease_commencement_date} onChange={(e: any) => { handleInputChange(e); removeInputError('lease_commencement_date') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="lease_commencement_dateError">Please select valid date.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Lease Expiration Date<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="date" className="form-control inputStyle" id="lease_expiration_date" value={InitialLeaseData.lease_expiration_date} onChange={(e: any) => { handleInputChange(e); setLeaseExpirationYear(e.target.value); removeInputError('lease_expiration_date') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="lease_expiration_dateError">Please select valid date.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Lease Expiration Year<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" disabled className="form-control inputStyle" id="lease_expiration_year" min="2023" step="1" value={InitialLeaseData.lease_expiration_year} onChange={handleInputChange} />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Annual Base Rent<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="annualized_base_rent" value={InitialLeaseData.annualized_base_rent} onChange={(e: any) => { handleInputChange(e); removeInputError('annualized_base_rent') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="annualized_base_rentError">Please enter valid rent value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Additional Facilities Cost<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="additional_facilities_cost" value={InitialLeaseData.additional_facilities_cost} onChange={(e: any) => { handleInputChange(e); removeInputError('additional_facilities_cost') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="additional_facilities_costError">Please enter valid cost.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Facilities Cost Fully Loaded<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="facilities_cost_fully_loaded" value={InitialLeaseData.facilities_cost_fully_loaded} onChange={(e: any) => { handleInputChange(e); removeInputError('facilities_cost_fully_loaded') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="facilities_cost_fully_loadedError">Please enter valid cost.</label>
                </div>
              </div>
             
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Critical Decision Date <span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="date" className="form-control inputStyle" id="critical_decision_date" value={InitialLeaseData.critical_decision_date} onChange={(e: any) => { handleInputChange(e); removeInputError('critical_decision_date') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="critical_decision_dateError">Please select valid date.</label>
                </div>
              </div>
              </div>
            {/* <div className="row " style={{ display: "flex", justifyContent: "space-between",marginTop:"100px" }}>
              <div style={{ flex: "1", textAlign: "left" }}>
                <i className="fa fa-angle-double-left fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"25px" }} onClick={(e) => changeActiveTab(1,2)}></i><label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"25px",color: "#0072B2"}} onClick={(e) => changeActiveTab(1,2)}>&nbsp;Previous</label>
              </div>
              <div style={{ flex: "1", textAlign: "right" }}>
                <label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"25px",color: "#0072B2"}} onClick={(e) => changeActiveTab(3,2)}>Next&nbsp;</label><i className="fa fa-angle-double-right fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"25px" }} onClick={(e) => changeActiveTab(3,2)}></i>
              </div>
            </div> */}
          </>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true} onChange={()=>setAccordion3(!accordion3)}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{color:"#0072B2", fontSize:"18px", fontFamily:"Poppins-SemiBold"}}>
          {accordion3?<i className="fa fa-minus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>:<i className="fa fa-plus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>}
          Strategy Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Strategy Type<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="strategy_type"
                    value={InitialLeaseData.strategy_type}
                    onChange={handleInputChange}
                  >
                    <option value="Primary">Primary</option>
                    <option value="None">None</option>
                  </select>
                </div>
              </div>


              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Strategy<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="strategy_optional_committed_or_na"
                    value={InitialLeaseData.strategy_optional_committed_or_na}
                    onChange={handleInputChange}
                  >
                    <option value="Committed">Committed</option>
                    <option value="Optional">Optional</option>
                    <option value="NA">NA</option>
                  </select>
                </div>
              </div>


              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Strategy Real Estate Initiative<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="strategy_real_estate_initiative"
                    name="strategy_real_estate_initiative"
                    value={InitialLeaseData.strategy_real_estate_initiative}
                    onChange={handleInputChange}
                  >
                    <option value="Change During Life of Lease">Change During Life of Lease</option>
                    <option value="Remain/Renew in Place">Remain/Renew in Place</option>
                    <option value="Exit at Termination">Exit at Termination</option>
                    <option value="Sublet">Sublet</option>
                    <option value="Acquisition">Acquisition</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Strategy Quarter Of Change<span style={{ color: "#FE8485" }}>*</span></label>
                  <select
                    className="form-control inputStyle"
                    id="strategy_quarter_of_change"
                    value={InitialLeaseData.strategy_quarter_of_change}
                    onChange={handleInputChange}
                  >
                    <option value="Q1">Q1</option>
                    <option value="Q2">Q2</option>
                    <option value="Q3">Q3</option>
                    <option value="Q4">Q4</option>
                  </select>
                </div>
              </div>


              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Strategy Top Opportunities<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="text" className="form-control inputStyle" id="strategy_top_opportunities" value={InitialLeaseData.strategy_top_opportunities} onChange={(e: any) => { handleInputChange(e); removeInputError('strategy_top_opportunities') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="strategy_top_opportunitiesError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label>Strategy Reduction / Addition / As Is<span style={{ color: "#FE8485" }}>*</span></label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio"
                      id="strategy_reduction_addition_or_as_is"
                      name="strategy_option"
                      value="Reduction"
                      checked={InitialLeaseData.strategy_reduction_addition_or_as_is === "Reduction"}
                      onChange={handleInputChange}
                    />
                    <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>Reduction</span>

                    <input
                      type="radio"
                      id="strategy_reduction_addition_or_as_is"
                      name="strategy_option"
                      value="Addition"
                      style={{ marginLeft: "10px" }}
                      checked={InitialLeaseData.strategy_reduction_addition_or_as_is === "Addition"}
                      onChange={handleInputChange}
                    />
                    <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>Addition</span>

                    <input
                      type="radio"
                      id="strategy_reduction_addition_or_as_is"
                      name="strategy_option"
                      value="As Is"
                      style={{ marginLeft: "10px" }}
                      checked={InitialLeaseData.strategy_reduction_addition_or_as_is === "As Is"}
                      onChange={handleInputChange}
                    />
                    <span style={{ marginLeft: "5px", fontFamily: "Poppins-Regular" }}>As Is</span>
                  </div>
                </div>
              </div>

            </div>
            <div className="row mt-3">
              <div className="col-md-2">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Strategy Head Count Adjustment In Year Of Change<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="strategy_head_count_adjustment_in_year_of_change" value={InitialLeaseData.strategy_head_count_adjustment_in_year_of_change} onChange={(e: any) => { handleInputChange(e); removeInputError('strategy_head_count_adjustment_in_year_of_change') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="strategy_head_count_adjustment_in_year_of_changeError">Please enter valid count.</label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Strategy Seat Count Adjustment In Year Of Change<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="strategy_seat_count_adjustment_in_year_of_change" value={InitialLeaseData.strategy_seat_count_adjustment_in_year_of_change} onChange={(e: any) => { handleInputChange(e); removeInputError('strategy_seat_count_adjustment_in_year_of_change') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="strategy_seat_count_adjustment_in_year_of_changeError">Please enter valid count.</label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Strategy Square Foot Adjustment In Year Of Change<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="strategy_square_foot_adjustment_in_year_of_change" value={InitialLeaseData.strategy_square_foot_adjustment_in_year_of_change} onChange={(e: any) => { handleInputChange(e); removeInputError('strategy_square_foot_adjustment_in_year_of_change') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="strategy_square_foot_adjustment_in_year_of_changeError">Please enter valid count.</label>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Strategy Occupancy Adjustment In Year Of Change<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="strategy_occupancy_adjustment_in_year_of_change" value={InitialLeaseData.strategy_occupancy_adjustment_in_year_of_change} onChange={(e: any) => { handleInputChange(e); removeInputError('strategy_occupancy_adjustment_in_year_of_change') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="strategy_occupancy_adjustment_in_year_of_changeError">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Strategy Year Of Change<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="strategy_year_of_change_yyyy" min="2023" step="1" value={InitialLeaseData.strategy_year_of_change_yyyy} onChange={(e: any) => { handleInputChange(e); removeInputError('strategy_year_of_change_yyyy') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="strategy_year_of_change_yyyyError">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Strategy Notes</label>
                  <textarea className="form-control inputStyle" id="strategy_notes" value={InitialLeaseData.strategy_notes} onChange={handleInputChange} />
                </div>
              </div>
            </div>
           {/*  <div className="row" style={{ display: "flex", justifyContent: "space-between" ,marginTop:"100px"}}>
              <div style={{ flex: "1", textAlign: "left" }}>
                <i className="fa fa-angle-double-left fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"25px" }} onClick={(e) => changeActiveTab(2,3)}></i><label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"25px",color: "#0072B2"}} onClick={(e) => changeActiveTab(2,3)}>&nbsp;Previous</label>
              </div>
              <div style={{ flex: "1", textAlign: "right" }}>
              <label style={{fontFamily:"Poppins-Medium",cursor:"pointer",fontSize:"25px",color: "#0072B2"}} onClick={(e) => changeActiveTab(4,3)}>Next&nbsp;</label><i className="fa fa-angle-double-right fa-3x" style={{ color: "#0072B2",cursor:"pointer",fontSize:"25px" }} onClick={(e) => changeActiveTab(4,3)}></i>
              </div>
            </div> */}
          </> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true} onChange={()=>setAccordion4(!accordion4)}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography style={{color:"#0072B2", fontSize:"18px", fontFamily:"Poppins-SemiBold"}}>
          {accordion4?<i className="fa fa-minus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>:<i className="fa fa-plus-circle" style={{marginRight:"10px"}} aria-hidden="true"></i>}
          Other Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Seats Capacity<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" min="0" id="capacity_seats" value={InitialLeaseData.capacity_seats} onChange={(e: any) => { handleInputChange(e); removeInputError('capacity_seats') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="capacity_seatsError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Surplus Deficit Seat Capacity<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="surplus_deficit_seat_capacity" value={InitialLeaseData.surplus_deficit_seat_capacity} onChange={(e: any) => { handleInputChange(e); removeInputError('surplus_deficit_seat_capacity') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="surplus_deficit_seat_capacityError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Parking Capacity<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="parking_capacity" min="0" value={InitialLeaseData.parking_capacity} onChange={(e: any) => { handleInputChange(e); removeInputError('parking_capacity') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="parking_capacityError">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Cost Employee<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="cost_employee" value={InitialLeaseData.cost_employee} onChange={(e: any) => { handleInputChange(e); removeInputError('cost_employee') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="cost_employeeError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Occupant Cost<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="cost_occupant" value={InitialLeaseData.cost_occupant} onChange={(e: any) => { handleInputChange(e); removeInputError('cost_occupant') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="cost_occupantError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Seat Cost<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="cost_seat" value={InitialLeaseData.cost_seat} onChange={(e: any) => { handleInputChange(e); removeInputError('cost_seat') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="cost_seatError">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >SF Employee<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="sf_employee" value={InitialLeaseData.sf_employee} onChange={(e: any) => { handleInputChange(e); removeInputError('sf_employee') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="sf_employeeError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >SF Occupant<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="sf_occupant" value={InitialLeaseData.sf_occupant} onChange={(e: any) => { handleInputChange(e); removeInputError('sf_occupant') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="sf_occupantError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >SF Seat<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="sf_seat" value={InitialLeaseData.sf_seat} onChange={(e: any) => { handleInputChange(e); removeInputError('sf_seat') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="sf_seatError">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Rentable SF<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="rentable_sf" value={InitialLeaseData.rentable_sf} onChange={(e: any) => { handleInputChange(e); removeInputError('rentable_sf') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="rentable_sfError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Cost SF<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="cost_sf" value={InitialLeaseData.cost_sf} onChange={(e: any) => { handleInputChange(e); removeInputError('cost_sf') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="cost_sfError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Cost SF 1<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="cost_sf_1" value={InitialLeaseData.cost_sf_1} onChange={(e: any) => { handleInputChange(e); removeInputError('cost_sf_1') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="cost_sf_1Error">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >SGA</label>
                  <input type="text" className="form-control inputStyle" id="sga" value={InitialLeaseData.sga} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >OPEX SGA</label>
                  <input type="text" className="form-control inputStyle" id="opex_sga" value={InitialLeaseData.opex_sga} onChange={handleInputChange} />
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >OPEX Total Revenues </label>
                  <input type="text" className="form-control inputStyle" id="opex_total_revenues" value={InitialLeaseData.opex_total_revenues} onChange={handleInputChange} />
                </div>
              </div>

            </div>
            
            <div className="row mt-3">
              

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Revenues<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="revenues" value={InitialLeaseData.revenues} onChange={(e: any) => { handleInputChange(e); removeInputError('revenues') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="revenuesError">Please enter valid value.</label>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >RMO Months<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" className="form-control inputStyle" id="rmo_months" value={InitialLeaseData.rmo_months} onChange={(e: any) => { handleInputChange(e); removeInputError('rmo_months') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="rmo_monthsError">Please enter valid value.</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label >Total Employee HC<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" min="0" className="form-control inputStyle" id="total_employee_hc" value={InitialLeaseData.total_employee_hc} onChange={(e: any) => { handleInputChange(e); removeInputError('total_employee_hc') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="total_employee_hcError">Please enter valid value.</label>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              

              <div className="col-md-4">
                <div className="form-group" style={{ textAlign: "start" }}>
                  <label  >Total Occupancy HC<span style={{ color: "#FE8485" }}>*</span></label>
                  <input type="number" min="0" className="form-control inputStyle" id="total_occupancy_hc" value={InitialLeaseData.total_occupancy_hc} onChange={(e: any) => { handleInputChange(e); removeInputError('total_occupancy_hc') }} />
                  <label className="" style={{ color: "#FE8485", display: "none" }} id="total_occupancy_hcError">Please enter valid value.</label>
                </div>
              </div>

            </div>
            

          </>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>







   {/*    <Dialog
      
        open={modalShow}
        onClose={() => setModalShow(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          onClick: () => { }, // Disable backdrop click
        }}
        PaperProps={{
          style: {
            backgroundColor:'#fff',
            textAlign: 'center',
            justifyContent:'center',
            position: 'absolute',
            top: '10%',
            padding:'0px' // Adjust the distance from the top as needed
            },
        }}
      >
        <DialogContent className='notifyBar'>
          <DialogContentText id="alert-dialog-description" >
            
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
 
      <Snackbar
  anchorOrigin={ {vertical: 'top', horizontal: 'center'} }
  open={modalShow}
  autoHideDuration={5000}
  onClose={() => setModalShow(false)}
  message={digContent}
  ContentProps={{
    className: 'notifyBar'
  }}
  key={'top' + 'center'}
  style={{marginTop:'100px'}}
/>
  </>
  )
}

export default Lease